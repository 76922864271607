import axios from 'axios'

export const BOOL_SELECT = [
  { name: 'ja', value: true }, 
  {name: 'nein', value: false}, 
  {name: 'ja/nein  ', value: null}
]
export const DEFAULT_FILTER_DOCUMENTS_FIELDS = {
  number: '',
  documentType: null,
  movementType: null,
  documentGroup: null,
  statusFrom: null,
  statusTo: null,
  dateFrom: '',
  dateTo: '',
  deliveryFrom: '',
  deliveryTo: '',
  preferredFrom: '',
  preferredTo: '',
  billingFrom: '',
  billingTo: '',
  createdBy: null,
  location: null,
  formGroup: null,
}

export const DEFAULT_FILTER_ADDRESSES_FIELDS = {
  customerSupplier: null,
  association: null,
  invoiceAddress: null,
  deliveryAddress: null,
  residence: null,
  salesPerson: null,
  healthInsurance: null,
  name: '',
  plzFrom: '',
  plzTo: '',
  country: null,
  remark: '',
  address: '',
  doctor: null,
  customerGroup: null,
}

export const DEFAULT_FILTER_CARE_FIELDS = {
  deductibleStatusFrom: null,
  deductibleStatusTo: null,
  vosStatusFrom: null,
  vosStatusTo: null,
  kvCreated: null,
  permanentCare: false,
  careType: null,
  interval: null,
  quarter: '',
  openAT: null,
  lastDeliveryFrom: '',
  lastDeliveryTo: '',
  vsdWithoutLastDelivery: null,
  reminderFrom: '',
  reminderTo: '',
  firstCare: null
}

export const DEFAULT_FILTER_DELIVERYBILLING_FIELDS = {
  billingDate: '',
  billingType: null,
  deliveryCondition: null,
  deliveryType: null,
  fibuAccount: null,
  location: null,
  terminal: null
}

export const DEFAULT_FILTER_OTHER_FIELDS = {
  article: null,
  charge: null,
  client: null,
  driver: null,
  internalId: null,
  object: null,
  positionText: null,
  processStateFrom: null,
  processStateTo: null,
  quantityId: null,
  referenceNumber: null,
  serialNumber: null,
  signature: null
}

//TODO: add delivery billing
//TODO: add other

export async function getDocumentById(wf_cid) {
  const response = await axios.get(`/v1/crm/documents/${wf_cid}`)

  return response
}

export async function printDocument(item) {
  item.printLoading = true

  const response = await axios.get(`/v1/crm/documents/${item.wf_cid}/pdf`, {
    responseType: 'blob',
  })

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'application/pdf' })
  )

  // const pdfDownloadPopUp = window.open(url, '_blank');
  // if(pdfDownloadPopUp) {
  //   pdfDownloadPopUp.focus();
  // }
  // // Fallback for when popup isn't allowed
  // else {
  const pdfLink = document.createElement('a')
  pdfLink.href = url
  pdfLink.download = item.name + '.pdf'
  pdfLink.target = '_blank'
  document.body.appendChild(pdfLink)
  pdfLink.click()
  document.body.removeChild(pdfLink)
  // }

  item.printLoading = false

  return response
}

export async function getAvailableUpgrades(wf_cid) {
  const response = await axios.get(`/v1/business/document/${wf_cid}/upgrades`, )

  return response
}

export async function upgradeDocument(wf_cid, upgrade) {
  const response = await axios.patch(
    `/v1/business/document/${wf_cid}/upgrade`,
    upgrade
  )

  return response
}

export async function getDocumentInteractions(wf_cid) {
  const response = await axios.get(`/v1/crm/documents/${wf_cid}/interactions`)

  return response.data
}
export async function getDocumentSubjects(wf_cid) {
  const response = await axios.get(
    `/v1/crm/documents/${wf_cid}/interactions/subjects`
  )

  return response.data
}

export async function AddDocumentInteraction(interaction) {
  await axios
    .post(`/v1/crm/documents/${interaction.wf_cid}/interactions`, interaction)
    .then((response) => {
      return response.data
    })
}

export async function editDocumentInteraction(interaction) {
  const response = await axios.put(
    `/v1/crm/documents/${interaction.wf_cid}/interactions`,
    interaction
  )

  return response.data
}

export async function deleteDocumentInteraction(wf_cid, interaction) {
  const response = await axios.delete(
    `/v1/crm/documents/${wf_cid}/interactions`,
    {
      data: interaction,
    }
  )

  return response.data
}

export async function searchDocuments(filterData, controller){
  try{
    const response = await axios.post(
      `/v1/documents`,
      filterData,
      {
        signal: controller.signal,
      }
    )
    return response?.data
  } catch (error) {
    if(axios.isCancel(error)){
      throw error
    }
  }
}

export async function searchObjects(search, controller) {
  const response = await axios.get(`/v1/crm/document/information/objects/search`, {    params: {
    q: search,
  },signal: controller.signal})

  return response.data
}



//TODO: Object Suche implementieren

//Filter API Calls
export async function getAllFilters(){
  const response = await axios.get(`/v1/documents/filter`)

  return response.data
}

export async function saveFilter(filterData, selectedDocuments){
  const response = await axios.post(`/v1/documents/filter`, {...filterData, wfcids: selectedDocuments})

  return response.data
}

export async function overwriteFilter(filterData, selectedDocuments){
  const response = await axios.put(`/v1/documents/filter`, {...filterData, wfcids: selectedDocuments})

  return response.data
}

export async function deleteFilter(filterData){
  const response = await axios.delete(`/v1/documents/filter/${filterData.id}`)

  return response.data
}

export async function getAllDocumentTypes(){
  const response = await axios.get(`/v1/crm/document/information/documentTypes`)

  return response.data
}

export async function getAllMovementTypes(){
  const response = await axios.get(`/v1/crm/document/information/movementTypes`)

  return response.data
}

export async function getAllDocumentGroups(){
  const response = await axios.get(`/v1/crm/document/information/documentGroups`)

  return response.data
}

export async function getAllStatuses(){
  const response = await axios.get(`/v1/crm/document/information/status`)

  return response.data
}

export async function getAllEditors(){
  const response = await axios.get(`/v1/crm/document/information/editors`)

  return response.data
}

export async function getAllBranchLocations(){
  const response = await axios.get(`/v1/crm/document/information/branch-offices`)

  return response.data
}

export async function getAllFormGroups(){
  const response = await axios.get(`/v1/crm/document/information/formGroups`)

  return response.data
}

export async function getAllResidences(){
  const response = await axios.get(`/v1/crm/document/information/residences`)

  return response.data
}

export async function getAllSalesPersons(){
  const response = await axios.get(`/v1/crm/document/information/salespersons`)

  return response.data
}

export async function getAllHealthInsuranceCompanies(){
  const response = await axios.get(`/v1/crm/document/information/healthInsuranceCompanies`)

  return response.data
}

export async function getAllCountries(){
  const response = await axios.get(`/v1/crm/document/information/countries`)

  return response.data
}

export async function getAllCustomerGroups(){
  const response = await axios.get(`/v1/crm/document/information/customerGroups`)

  return response.data
}

//Versorgung
export async function getAllSBHStates(){
  const response = await axios.get(`/v1/crm/document/information/deductibleStates`)

  return response.data
}

export async function getAllVOSStates(){
  const response = await axios.get(`/v1/crm/document/information/prescriptionStates`)

  return response.data
}

export async function getAllCareTypes(){
  const response = await axios.get(`/v1/crm/document/information/careTypes`)

  return response.data
}

export async function getAllIntervals(){
  const response = await axios.get(`/v1/crm/document/information/intervals`)

  return response.data
}

export async function getAllQuarters(){
  const response = await axios.get(`/v1/crm/document/information/quarters`)

  return response.data
}


//DeliverBilling
export async function getAllDeliveryTypes(){
  const response = await axios.get(`/v1/crm/document/information/deliveryTypes`)

  return response.data
}

export async function getAllDeliveryConditions(){
  const response = await axios.get(`/v1/crm/document/information/deliveryConditions`)

  return response.data
}

export async function getAllFibuAccounts(){
  const response = await axios.get(`/v1/crm/document/information/fibuAccounts`)

  return response.data
}

export async function getAllBillingTypes(){
  const response = await axios.get(`/v1/crm/document/information/billingTypes`)

  return response.data
}

export async function getAllTerminals(){
  const response = await axios.get(`/v1/crm/document/information/terminals`)

  return response.data
}

export async function getAllLocations(){
  const response = await axios.get(`/v1/crm/document/information/locations`)

  return response.data
}

export async function getAllClients(){
  const response = await axios.get(`/v1/crm/document/information/clients`)

  return response.data
}

export async function getAllProcessStates(){
  const response = await axios.get(`/v1/crm/document/information/processStates`)

  return response.data
}